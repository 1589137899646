import { Customers_Profile_PublicContactPreferencesMutationNLRequestModel } from '@monorepo-types/dc';
import { DC_Domain_Models_CustomerRatings_BusinessType } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_Customers_ChamberOfCommerce_ChamberOfCommerceSearchRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Discontinue_Confirm_CustomerDiscontinueConfirmRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Discontinue_Intake_CustomerDiscontinueIntakeRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_SwitchType_SwitchTypeRequestModel } from '@monorepo-types/dc';
import { ResponseDataDC_Domain_Models_Products_OrdersV2_OrderType } from '@monorepo-types/dc';
import { ResponseDataSystem_Guid } from '@monorepo-types/dc';
import { ResponseModels_Customers_ChamberOfCommerce_ChamberOfCommerceSearchResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_CustomerRatings_AverageReviewScoreResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_Discontinue_Intake_CustomerDiscontinueIntakeResponseModel } from '@monorepo-types/dc';

import { request } from '../client';

type GetSwitchType = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody?: RequestModels_Customers_SwitchType_SwitchTypeRequestModel;
};
/**
 * GetSwitchType
 * Returns a OrderType: INH, WSW,PSW, CIPS
 * @returns ResponseDataDC_Domain_Models_Products_OrdersV2_OrderType Success
 */
export function getSwitchType({
  businessUnit,
  label,
  requestBody,
}: GetSwitchType): Promise<ResponseDataDC_Domain_Models_Products_OrdersV2_OrderType> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/${businessUnit}/${label}/customers/switchtype`,
    body: requestBody,
    errors: { 400: 'Bad Request' },
  });
}

type UnsubscribeFromContactPreference = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  recipient: string;
  requestBody?: Customers_Profile_PublicContactPreferencesMutationNLRequestModel;
};
/**
 * UnsubscribeFromContactPreference
 * Update customer preference
 * @returns void
 */
export function unsubscribeFromContactPreference({
  businessUnit,
  label,
  recipient,
  requestBody,
}: UnsubscribeFromContactPreference): Promise<void> {
  return request({
    method: 'PATCH',
    path: `/dxpweb/public/${businessUnit}/${label}/preferences/contact`,
    query: { recipient },
    body: requestBody,
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}

type GetCustomerRatings = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  businessType?: DC_Domain_Models_CustomerRatings_BusinessType;
};
/**
 * GetCustomerRatings
 * Get average customer rating score from Expoints
 * @returns ResponseModels_Customers_CustomerRatings_AverageReviewScoreResponseModel Success
 */
export function getCustomerRatings({
  businessUnit,
  label,
  businessType,
}: GetCustomerRatings): Promise<ResponseModels_Customers_CustomerRatings_AverageReviewScoreResponseModel> {
  return request({
    method: 'GET',
    path: `/dxpweb/public/${businessUnit}/${label}/averagereviewscore`,
    query: { businessType },
    errors: { 404: 'Not Found' },
  });
}

type ChamberOfCommerceSearch = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody?: RequestModels_Customers_ChamberOfCommerce_ChamberOfCommerceSearchRequestModel;
};
/**
 * ChamberOfCommerceSearch
 * Requests basic business information.
 * @returns ResponseModels_Customers_ChamberOfCommerce_ChamberOfCommerceSearchResponseModel Success
 */
export function chamberOfCommerceSearch({
  businessUnit,
  label,
  requestBody,
}: ChamberOfCommerceSearch): Promise<ResponseModels_Customers_ChamberOfCommerce_ChamberOfCommerceSearchResponseModel> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/${businessUnit}/${label}/customers/chamberofcommerce/search`,
    body: requestBody,
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}

type PostCustomerOrderConfirmation = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  orderId: string;
};
/**
 * PostCustomerOrderConfirmation
 * Customers receive an email after placing an order with a confirmation button. Pressing this button sends a confirmation towards CRM
 * @returns any Success
 */
export function postCustomerOrderConfirmation({
  businessUnit,
  label,
  orderId,
}: PostCustomerOrderConfirmation): Promise<any> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/${businessUnit}/${label}/orders/${orderId}/confirmation`,
    errors: { 400: 'Bad Request' },
  });
}

type PostCustomerDiscontinueIntake = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody: RequestModels_Customers_Discontinue_Intake_CustomerDiscontinueIntakeRequestModel;
};
/**
 * PostCustomerDiscontinueIntake
 * Post customer discontinue intake
 * @returns ResponseModels_Customers_Discontinue_Intake_CustomerDiscontinueIntakeResponseModel Success
 */
export function postCustomerDiscontinueIntake({
  businessUnit,
  label,
  customerId,
  requestBody,
}: PostCustomerDiscontinueIntake): Promise<ResponseModels_Customers_Discontinue_Intake_CustomerDiscontinueIntakeResponseModel> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/${businessUnit}/${label}/customers/${customerId}/discontinue/intake`,
    body: requestBody,
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}

type PostCustomerDiscontinueConfirm = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody: RequestModels_Customers_Discontinue_Confirm_CustomerDiscontinueConfirmRequestModel;
};
/**
 * PostCustomerDiscontinueConfirm
 * Posts the customer discontinue confirm (Customer is deceased).
 * @returns ResponseDataSystem_Guid Success
 */
export function postCustomerDiscontinueConfirm({
  businessUnit,
  label,
  customerId,
  requestBody,
}: PostCustomerDiscontinueConfirm): Promise<ResponseDataSystem_Guid> {
  return request({
    method: 'POST',
    path: `/dxpweb/public/${businessUnit}/${label}/customers/${customerId}/discontinue/confirm`,
    body: requestBody,
    errors: { 400: 'Bad Request', 404: 'Not Found' },
  });
}
